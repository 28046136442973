<template>
  <div>
    <v-container
      class="v-container-fluid"
      fluid
    >
      <v-row>
        <v-col cols="12">
          <div class="container-profile">
            <v-container
              style="width: 100%;"
              fluid
            >
              <v-row v-if="isResponsive">
                <v-col cols="12">
                  <div>
                    <p class="text-title mon-bold">{{ texts.order.textTitle }}</p>
                  </div>
                </v-col>
                <v-col cols="12">
                  <div class="display-flex align-items-center">
                    <v-text-field
                      v-model="sSearch"
                      type="text"
                      outlined
                      color="#000000"
                      dense
                      class="global-inputs mon-regular mr-2"
                      :placeholder="texts.order.filters.textSearch"
                      persistent-placeholder
                      clearable
                      append-icon="mdi-magnify"
                      maxLength="100"
                    ></v-text-field>
                    <order-filter-layout
                      :tab="tab"
                      :texts="texts"
                    />
                  </div>
                  <div class="display-flex align-items-center mt-4">
                    <v-menu
                      ref="bMenuFinal"
                      v-model="bMenuFinal"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          outlined
                          color="#000000"
                          dense
                          class="global-inputs inputs-date mon-regular mr-2"
                          readonly
                          v-model="startDate"
                          clearable
                          hide-details
                          append-icon="mdi-calendar"
                          @click:clear="startDate = null; endDate = null;"
                          :placeholder="texts.order.filters.startDate"
                          :label="texts.order.filters.startDate"
                          persistent-hint
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        color="#C41F79"
                        v-model="startDate"
                        no-title
                        @input="bMenuFinal = false"
                      ></v-date-picker>
                    </v-menu>
                  </div>
                  <div class="display-flex align-items-center mt-4">
                    <v-menu
                      ref="bMenuInitial"
                      v-model="bMenuInitial"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          outlined
                          color="#000000"
                          dense
                          class="global-inputs inputs-date mon-regular mr-2"
                          readonly
                          v-model="endDate"
                          clearable
                          hide-details
                          append-icon="mdi-calendar"
                          @click:clear="endDate = null;"
                          :placeholder="texts.order.filters.endDate"
                          :label="texts.order.filters.endDate"
                          persistent-hint
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        :min="startDate"
                        color="#C41F79"
                        v-model="endDate"
                        no-title
                        @input="bMenuInitial = false"
                      ></v-date-picker>
                    </v-menu>
                  </div>
                </v-col>
              </v-row>
              <v-row v-else>
                <v-col
                  cols="12"
                  md="4"
                >
                  <div>
                    <p class="text-title mon-bold">{{ texts.order.textTitle }}</p>
                  </div>
                </v-col>
                <!-- filters -->
                <v-col
                  cols="12"
                  md="8"
                >
                  <div class="display-flex align-items-center">
                    <v-text-field
                      v-model="sSearch"
                      type="text"
                      outlined
                      color="#000000"
                      dense
                      class="global-inputs mon-regular mr-2"
                      :placeholder="texts.order.filters.textSearch"
                      persistent-placeholder
                      clearable
                      append-icon="mdi-magnify"
                      maxLength="100"
                    ></v-text-field>
                    <v-menu
                      ref="bMenuFinal"
                      v-model="bMenuFinal"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          outlined
                          color="#000000"
                          dense
                          class="global-inputs inputs-date mon-regular mr-2"
                          readonly
                          v-model="startDate"
                          clearable
                          hide-details
                          append-icon="mdi-calendar"
                          @click:clear="startDate = null; endDate = null;"
                          :placeholder="texts.order.filters.startDate"
                          :label="texts.order.filters.startDate"
                          persistent-hint
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        color="#C41F79"
                        v-model="startDate"
                        no-title
                        @input="bMenuFinal = false"
                      ></v-date-picker>
                    </v-menu>
                    <v-menu
                      ref="bMenuInitial"
                      v-model="bMenuInitial"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          outlined
                          color="#000000"
                          dense
                          class="global-inputs inputs-date mon-regular mr-2"
                          readonly
                          v-model="endDate"
                          clearable
                          hide-details
                          append-icon="mdi-calendar"
                          @click:clear="endDate = null;"
                          :placeholder="texts.order.filters.endDate"
                          :label="texts.order.filters.endDate"
                          persistent-hint
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        :min="startDate"
                        color="#C41F79"
                        v-model="endDate"
                        no-title
                        @input="bMenuInitial = false"
                      ></v-date-picker>
                    </v-menu>
                    <order-filter-layout
                      :tab="tab"
                      :texts="texts"
                    />
                  </div>
                </v-col>
              </v-row>
            </v-container>
            <template>
              <v-tabs
                v-model="tab"
                show-arrows
                background-color="transparent"
              >
                <v-tabs-slider class="separator-line-tab"></v-tabs-slider>
                <v-tab
                  @click="tab1"
                  class="tabs-profile mon-regular"
                >{{ texts.order.textTab1 }}</v-tab>
                <v-tab
                  @click="tab2"
                  class="tabs-profile mon-regular"
                >{{ texts.order.textTab2 }}</v-tab>
                <!-- <v-tab
                  @click="tab3"
                  class="tabs-profile mon-regular"
                >{{ texts.order.textTab3 }}</v-tab> -->
              </v-tabs>

              <v-tabs-items  :touchless="true" v-model="tab">
                <v-tab-item>
                  <order-tab-content-one-layout :texts="texts"></order-tab-content-one-layout>
                </v-tab-item>
                <v-tab-item>
                  <order-tab-content-two-layout :texts="texts"></order-tab-content-two-layout>
                </v-tab-item>
                <!-- <v-tab-item>
                  <order-tab-content-three-layout :texts="texts"></order-tab-content-three-layout>
                </v-tab-item> -->
              </v-tabs-items>
            </template>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "OrderContentLayout",
  props: {
    texts: {
      type: Object,
    },
  },
  data() {
    return {
      //VARIABLES
      tab: 0,
      sSearch: "",
      bMenuFinal: false,
      bMenuInitial: false,
      startDate: null,
      endDate: null,
      status: "",
      paymentStatus: "",
      isResponsive: false,
    };
  },
  beforeMount() {
    if (this.$route.path == "/order/tab/1") {
      this.tab = 0;
    } else if (this.$route.path == "/order/tab/2") {
      this.tab = 1;
    } else if (this.$route.path == "/order/tab/3") {
      this.tab = 2;
    }
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize: function () {
      if (window.innerWidth < 960) {
        this.isResponsive = true;
      } else {
        this.isResponsive = false;
      }
    },
    clearFilters: function () {
      this.sSearch = "";
      this.$store.commit("setSearch", "");
      this.startDate = null;
      this.$store.commit("setStartDate", null);
      this.endDate = null;
      this.$store.commit("setEndDate", null);
      this.$store.commit("setOrderStatus", []);
      this.$store.commit("setPaymentStatus", null);
    },
    tab1: function () {
      this.clearFilters();
      this.$router
        .push({
          path: "/order/tab/1",
        })
        .catch(() => {});
    },
    tab2: function () {
      this.clearFilters();
      this.$router
        .push({
          path: "/order/tab/2",
        })
        .catch(() => {});
    },
    tab3: function () {
      this.clearFilters();
      this.$router
        .push({
          path: "/order/tab/3",
        })
        .catch(() => {});
    },
  },
  watch: {
    $route(to, from) {
      if (to.path == "/order/tab/1") {
        this.tab = 0;
      } else if (to.path == "/order/tab/2") {
        this.tab = 1;
      } else if (to.path == "/order/tab/3") {
        this.tab = 2;
      }
    },
    sSearch: function () {
      this.$store.commit("setSearch", this.sSearch);
    },
    startDate: function () {
      this.$store.commit("setStartDate", this.startDate);
    },
    endDate: function () {
      this.$store.commit("setEndDate", this.endDate);
    },
  },
};
</script>

<style>
.container-profile .v-slide-group__content {
  margin-bottom: 4px !important;
}
</style>

<style scoped>
.separator-line-tab {
  background: transparent linear-gradient(268deg, #ff8181 0%, #ffa77e 100%) 0%
    0% no-repeat padding-box;
  height: 40px;
  margin-left: 2px;
}

.tabs-profile {
  font-size: 14px;
  text-transform: initial;
  letter-spacing: 0px;
  text-align: center;
}

.v-tab--active {
  font-family: "montserrat-bold", sans-serif;
  color: #3d414a !important;
}

.v-tabs-items {
  background-color: transparent !important;
}

.theme--light.v-tabs .v-tab:focus::before {
  opacity: 0;
}

.theme--light.v-tabs .v-tab--active:hover::before,
.theme--light.v-tabs .v-tab--active::before {
  opacity: 0;
}

.text-title {
  text-align: left;
  font-size: 35px;
  letter-spacing: 0px;
  color: #151d27;
  opacity: 1;
  margin-bottom: 0px;
}
</style>